import { FaPlus } from 'react-icons/fa';
import { addDimTableRow, getAddFormDropdownOptions, getEmptyDimTableObject } from '../services/dimension-service';
import { useEffect, useState } from 'react';
import { InvalidEntries } from '../pop-ups/invalid-entries';
import Loader from '../navigation/Loader';
import { Dropdown } from '../shared/Dropdown';
import InputDropdown from './InputDropdown';
import toast from 'react-hot-toast';
import { useGetData, useGetToken } from '../hooks/custom-hooks';
import { overrideEndpoints } from '../endpoint-utility';

export const AddRows = ({ dimTable, preFilledValues }) => {  
    
    const dimTableObject = useGetData(getEmptyDimTableObject, null, { dimTable });
    const token = useGetToken();

    const [showInvalidEntries, setShowInvalidEntries] = useState(false);
    const [invalideEntries, setInvalidEntries] = useState([]);
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewableDropdownOptions, setViewableDropdownOptions] = useState([]);

    let isArray = function(a) {
        return(!!a) && (a.constructor === Array);
    }

    // useEffect(() => {
    //     console.log(fields);
    // } ,[fields]);

    useEffect(() => {
        if(dimTableObject !== null) {
            setLoading(false);
            setFields(dimTableObject.map((item) => ({
                ...item,
                currentValue: preFilledValues?.find((preset) => preset.columnName.toLowerCase() === item.columnName.toLowerCase())?.presetValue ?? '',
                isFocused: false,
                isReadOnly: preFilledValues?.some((preset) => preset.columnName.toLowerCase() === item.columnName.toLowerCase())
            })));
        } else {
            setLoading(true);
        }
    },[dimTableObject]);

    const handleInputs = async () => {

        const copiedFields = [...fields];

        const jsonToSend = copiedFields.reduce((result, obj) => {
            result[obj.columnName] = obj.currentValue;
            return result;
        }, {});

        let newRow = await addDimTableRow(dimTable, jsonToSend, token); 
        
        if(newRow.error) {
            if(isArray(newRow.error.message)) {
                setInvalidEntries(newRow.error.message);
                setShowInvalidEntries(true);
            } else {
                toast.error(newRow.error.message);
            }
            
        } else {
            toast.success(`Row added!`);
            window.location.reload();
        }
    }

    const handleDropdownDisplay = async (columnName, item) => {
        const getDropdownOptions = async () => {
            let response = [];

            console.log(item);
            if(item.overrideDropdownValues !== null) {
                console.log('reached');
                const overrideName = overrideEndpoints.find((override) => override.overrideValue === item.overrideDropdownValues).serviceCall;
                response = await overrideName({searchValue: ''}, token);
            }
            else {
                response = await getAddFormDropdownOptions({
                    dimTable: dimTable,
                    columnName: columnName,
                    filteredText: '',
                    keyColumnName: item.includeKeyValues ? item.keyColumnName : null
                }, token);
            }

            const formattedOptions = response.data.map(item => ({ customvalue1: columnName, id: item, name: item }));
            setViewableDropdownOptions(formattedOptions);
        }
        getDropdownOptions();
    }

    const handleInputFocus = async (newVal, columnName) => {

       let dataCopy = [...fields];

        const inputOptions = dataCopy.map((item) => {
                if (item.columnName.toLowerCase() === columnName.toLowerCase()) {
                return {
                    ...item,
                    currentValue: newVal,
                    isFocused: true
                };
                } else {
                return item; 
                }
            });

        setFields(inputOptions);

    };

    const discreteDropdownChange = (selected, ddOptions) => {
        const selectedOption = ddOptions.find((item) => item.name === selected);

        // Access the customvalue1 from the selected option and store it in a variable
        const columnName = selectedOption.customvalue1;
        
        handleDropdownOption(selected, columnName);
    }

    const handleDropdownOption = (selectedOption, columnName) => {

        let dataCopy = [...fields];

        const updatedVals = dataCopy.map((item) => {
            if (item.columnName.toLowerCase() === columnName.toLowerCase()) {
                return {
                ...item,
                currentValue: selectedOption?.value ? selectedOption.value : selectedOption,
                isFocused: false,
                displayValue: selectedOption?.value ? selectedOption.displayText : selectedOption
                };
            }
            return item; // return the unchanged item if the condition is not met
        });

        setFields(updatedVals);   
    };

    if(loading) {
        return(
            <Loader/>
        );
    }

    return (
        <div className="action-container">
            <div className="column-list-container">
                <div className="column-list">
                    {fields.map((item, index) => (
                        <div className="column-entry-section" key={index}>
                            <div className="column-labels" key={`label=${index}`}>{item.columnName}</div>
                            {item.isReadOnly &&
                                <input 
                                    type="text" 
                                    className="data-entry" 
                                    key={index} 
                                    placeholder={item.placeholder} 
                                    defaultValue={item.currentValue}
                                    disabled
                                />
                            }

                            {item.hasDropdownOptions && !item.isDropdownRestricted && !item.isReadOnly &&
                                <InputDropdown
                                    fieldParams={item}
                                    className={'addrows-input-dd'}
                                    handleInputChange={(event) => handleInputFocus(event.target.value, item.columnName)}
                                    handleFocus={(event) => handleInputFocus(event.target.value, item.columnName)}
                                    handleDropdownOption={handleDropdownOption}
                                    dropdownOptions={item.dropdownOptions}
                                    token={token}
                                    dimTable={dimTable}
                                />
                            }

                            {item.isDropdownRestricted && !item.isReadOnly &&
                                <div style={{width:'100%'}}
                                    onFocus={() => handleDropdownDisplay(item.columnName, item)}>
                                    <Dropdown
                                        id={item.columnName + '-dd'}
                                        value={item.currentValue}
                                        dropdownValues={viewableDropdownOptions} 
                                        handleDropdownChange={(event) => discreteDropdownChange(event.target.value, viewableDropdownOptions)} 
                                        label={null} 
                                        className="addrow-dd"
                                        defaultPhrase={'Select ' + item.columnName}
                                        // handleValueLocally={true}
                                    />
                                </div>
                                //<InputDropdown dimTable={dimTable} columnName={item.columnName} className={'addrows-input-dd'}/>
                            }
                            {!item.hasDropdownOptions && !item.isReadOnly &&
                                <input 
                                    type="text" 
                                    className="data-entry" 
                                    key={index} 
                                    placeholder={item.placeholder} 
                                    defaultValue={item.currentValue}
                                    onKeyUp={(event) => handleInputFocus(event.target.value, item.columnName)}
                                />
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className="button btn1 action-btn-row"
                onClick={handleInputs}>
                <span>Add Row</span>
                <FaPlus size={10}/>
            </div>

            <InvalidEntries isVisible={showInvalidEntries} invalidEntries={invalideEntries} handleCancelAction={() => setShowInvalidEntries(false)}/>

        </div>
        
        
    );
}