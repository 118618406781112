import toast from "react-hot-toast";
import { useGetToken } from "../hooks/custom-hooks";
import { resolveDuplicates } from "../services/dimension-service";
import { MapTable } from "../shared/MapTable";


export function DuplicateKeyRows({dimTable, dupeData, dupeHeaders, year}) {

    const isEmpty = dupeData.length == 0;
    const token = useGetToken();

    const handleResolve = () => {
        const resolveDimDupes = async () => {
            let response = await resolveDuplicates(dimTable, year, token);

            if(response.data) {
                toast.success('Duplicates Resolved');
                window.location.reload();
            } else {
                toast.error('There was an error resolving the duplicates')
            }
        }
        resolveDimDupes();
    }

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden'}}>
            {!isEmpty &&
                <>
                    <MapTable 
                        tableData={dupeData}
                        tableHeaders={dupeHeaders}
                        cellWidth="180px" 
                        dimTable={dimTable} 
                        itemsPerPage={50}
                    />

                    <span onClick={handleResolve} className="btn btn1">Resolve</span>
                </>
                 
            }

            {isEmpty &&
                <span style={{fontSize: '30px'}}>NO DUPLICATE IDs</span>
            }
        </div>
              
    );

}