import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import { HomePage } from './full-pages/HomePage';
import { useEffect, useState } from 'react';
import { FullPageErrorReport } from './full-pages/FullPageErrorReport';
import { loginRequest } from './auth-provider';
import { MsalProvider, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Dropdown, DropdownButton} from 'react-bootstrap'
import { InteractionStatus } from '@azure/msal-browser';
import { AddRowsUpload } from './full-pages/AddRowsUpload';
import { DimTableLanding } from './full-pages/DimTableLanding';
import { Layout } from './layout/layout';
import { getCurrentUser } from './services/claims-service';
import { Client } from "@microsoft/microsoft-graph-client";
import { msalConfig } from './auth-provider';
import { CurrentUser } from './partials/CurrentUser';
import { Toaster } from 'react-hot-toast';
import { TicketOpsAdmin } from './full-pages/TicketOpsAdmin';
import { Banner } from './partials/banner';

export const App = ({instance}) => {
  const navigate = useNavigate();

  useEffect(() => {
    handleLoginRedirect();
  }, []);

  useEffect(() => {
    instance.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  }, [instance])

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  };

  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <div style={{display: 'flex', flexDirection: 'column'}}>
           <Banner/>
           <Toaster 
              toastOptions={{
                style: {
                  background: '#363636',
                  color: '#fff',
                },
                success: {
                  duration: 3000,
                  theme: {
                    primary: 'green',
                    secondary: 'black',
                  }
                },
                position: "bottom-right"
              }}
            />
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/:dimTable' element={<DimTableLanding/>}/>
            <Route path='/ErrorReport/:dimTable' element={<FullPageErrorReport/>} />
            <Route path='/UploadRows/:dimTable' element={<AddRowsUpload/>} />
            <Route path='/AddRows/:dimTable' element = {<AddRowsUpload/>} />
            <Route path='/TicketOpsAdmin' element={<TicketOpsAdmin/>} />
          </Routes>
        </div>
        
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="banner">
          <div className='banner-left' onClick={() => navigate('/')}>
            <img style={{width: '100px'}} src='https://appcontentimages.blob.core.windows.net/$web/snake-blackTeal.png'/>
            <span className='banner-header'>Ticket Admin Portal</span>
          </div>
        </div>  
        <div style={{display: 'flex', height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center'}} onLoad={handleLoginRedirect}>
            <span className='button btn1' style={{textAlign: 'center'}} onClick={handleLoginRedirect}>Log in</span>
        </div>
    </UnauthenticatedTemplate>
    </MsalProvider>    
  );
};