import { useState } from 'react';
import '../App.css';
import { SingleRunPopUp } from '../pop-ups/single-run-info';
import '../styles/Modules.css';
import { FaClock } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useGetToken } from '../hooks/custom-hooks';
import { getLogicAppCurrentStatus } from '../services/dimension-service';

export function CustomInterval() {

    const [singleRunIsVisible, setSingleRunIsVisible] = useState(false);
    const token = useGetToken();

    const handleHidePopup = (event) => {
        event.stopPropagation();
        setSingleRunIsVisible(false);
    }

    const showPopup = () => {
        const getRunStatus = async () => {
            let response = await getLogicAppCurrentStatus(token);

            if(!response.data) {
                setSingleRunIsVisible(false);
                toast.error('Cannot run custom interval. There is a process currently running.');
            } else {
                setSingleRunIsVisible(true);
            }
            
        }
        getRunStatus();
    }

    const handleIsApiRunning = (isApiRunning) => {
        console.log(isApiRunning);

        if(isApiRunning) {
            setSingleRunIsVisible(false);
            toast.error('Cannot run custom interval. There is a process currently running.');
        }
    }

    return (
        <div className="module-container-slim" onClick={showPopup}>
            <div className="icon-text-row App-text"><FaClock style={{color: '#3fc2cd', fontSize: '25px'}}/> Run Custom Interval</div>

            <SingleRunPopUp 
                isVisible={singleRunIsVisible} 
                showInputs={true}
                runInfo={null}
                handleCancelAction={handleHidePopup}
                onCompleteAction={() => alert('tester')}
                isApiRunningConst={handleIsApiRunning}
            />
        </div>        
    );
}