import { callExternalApi } from "./api-service";

const apiServerUrl = process.env.REACT_APP_BASE_API_URL;

export const getCurrentUser = async (accessToken) => {
    const config = {
        url: `${apiServerUrl}/GetCurrentUser`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },  
        data: null
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
} 