import { useEffect, useState } from 'react';
import '../App.css';
import '../styles/Modules.css';
import { FaInfoCircle, FaCheckCircle, FaExclamationCircle, FaEye, FaPlus, FaEdit, FaCircle, FaExclamationTriangle } from 'react-icons/fa';
import { getDimensionsBySeason } from '../services/dimension-service';
import { InfoPopup } from '../pop-ups/info-popup';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from '../shared/Dropdown';
import { useGetData, useGetToken } from '../hooks/custom-hooks';
import { LoadingModule } from './LoadingModule';


export function DimTablesPeek({ seasonYear, yearDropdownList }) {
    const navigate = useNavigate();

    const [selectedYear, setSelectedYear] = useState(seasonYear);
    const [isloading, setIsLoading] = useState(true);

    const dimTableList = useGetData(getDimensionsBySeason, null, {seasonYear: selectedYear}, [selectedYear]);

    useEffect(() => {
        if(dimTableList !== null) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    },[dimTableList]);

    const [isHovered, setIsHovered] = useState(false);
    const [factInfoIsVisible, setFactInfoIsVisible] = useState(false);

    const handleInfoHover = () => {
        setFactInfoIsVisible(true);
    }

    const handleClick = (dimTable, conflicts) => {
        navigate(`/${dimTable}?year=${selectedYear}&conflicts=${conflicts}`);
    }

    function handleErrorReportClick(table, conflicts, event) {
        event.stopPropagation();

        const url = `/ErrorReport/${table}?year=${selectedYear}&conflicts=${conflicts}`;
        navigate(url);
    }

    function handleManagementClick(table, conflicts, event) {
        event.stopPropagation();
        navigate(`/AddRows/${table}?year=${selectedYear}&conflicts=${conflicts}`);
    }

    function handleYearChange(event) {
        let dropdown = event.target;
        let year = dropdown.value;

        setSelectedYear(year);
        setIsLoading(true);               
    }

    if(isloading) {
        return (
            <LoadingModule/>
        );
    }

    return (
        <div className='module-container dim-dashboard'>
            <div className='App-text dashboard-header header-icon-row'>
                <span>DimTables Management</span> 
                <div className='title-row-dropdown-info'>
                    <Dropdown 
                        id="dimTablesYearDropdown" 
                        value={selectedYear}
                        dropdownValues={yearDropdownList} 
                        handleDropdownChange={handleYearChange} 
                        label="" 
                        className="form-fieldgroup"
                        defaultPhrase={null}
                    />
                    <FaInfoCircle style={{cursor: 'pointer', width: '50px'}}
                        onClick={handleInfoHover}/>
                </div>
            </div>

            <div className='table-header-row'>
                <span className='dimTable-data dimTable-name dimTable-header'>DimTable Name</span>
                <span className='dimTable-data dimTable-errorCount dimTable-header'>Errors | Conflicts</span>
            </div>
            <div className='scrollable-container'>
                {dimTableList.map((item, index) => (
                    <div
                        className='list-line'
                        key={index}
                        onClick={() => handleClick(item.dimTableName, item.totalConflicts)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                            <span className='App-text dimTable-data dimTable-name'>{item.dimTableName}</span>
                            
                            {item.totalConflicts > 0 &&
                                <span className='App-text dimTable-data dimTable-errorCount icon-text-row'>
                                    <span className='error'>{item.totalErrors} | {item.totalConflicts}</span>
                                    <FaExclamationCircle color='#a71930'/>
                                </span>
                            }

                            {item.totalConflicts == 0 && item.totalErrors == 0 &&
                            <span className='App-text dimTable-data dimTable-errorCount'><FaCheckCircle color='green'/></span>}

                            {isHovered && 
                                <div className='quicklink-buttons'>
                                    <button className='ql-action-btn add-btn' onClick={(event) => handleManagementClick(item.dimTableName, item.totalConflicts, event)}>
                                        <FaPlus/>
                                        <span>Add Rows</span>
                                    </button>
                                    { item.totalConflicts > 0 && 
                                        <button className='ql-action-btn error-btn' onClick={(event) => handleErrorReportClick(item.dimTableName, item.totalConflicts, event)}>
                                            <FaExclamationTriangle/>
                                        <span>Error Report</span>
                                    </button>}
                                </div>
                                }
                            
                    </div>
                ))}
            </div>

            <InfoPopup 
                isVisible={factInfoIsVisible} 
                headerText={'DimTables Management'} 
                handleCancelAction={() => setFactInfoIsVisible(false)}
            />

        </div>        
    );
}
