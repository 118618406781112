import Loader from "../navigation/Loader";
import '../styles/modal.css';
import { FaTimes } from 'react-icons/fa';

export const InvalidEntries = ({ isVisible, invalidEntries, handleCancelAction, onCompleteAction, loadingState }) => {
    if(!isVisible) {
        return null;
    }

    return(
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <div>Invalid Data Entry</div>
                        <span className="subtitle subtitle-border">
                            <span>Please correct the following issues</span>
                        </span>
                    </div>
                    <div className="modal-body invalid-list">
                        {Array.from(invalidEntries).map((item, index) => (
                            <div>
                                {/* <span>{item.propertyName}</span> */}
                                <span>{item.errorMessage}</span>
                            </div>
                        ))}
                    </div>
                    <div className="modal-footer">
                        <button className="button btnCancel action-btn-row" onClick={handleCancelAction}>
                            <span>Close</span>
                            <FaTimes size={12} />
                        </button>
                    </div>
                </div>
            </div>
        )
}