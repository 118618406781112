import axios from "axios";

let isArray = function(a) {
  return(!!a) && (a.constructor === Array);
}

export const callExternalApi = async (options) => {
  try {
    const response = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    console.log(error);
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;
      console.log(axiosError);

      let message = "http request failed";
      let validationMessages = [];

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if(response && response.data && isArray(response.data)) {
        validationMessages = response.data;
      }

      if (response && response.data) {
        message = response.data;
        validationMessages = null;
      }

      return {
        data: null,
        error: {
          message,
          validationMessages
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
}