import { useEffect, useState } from "react";
import { uploadDimTableCSV } from "../services/dimension-service";
import '../styles/DimTables.css';
import { render } from "react-dom";
import { MapTable } from "../shared/MapTable";
import { useAccount, useMsal } from "@azure/msal-react";
import toast from "react-hot-toast";
import { useGetToken } from "../hooks/custom-hooks";
import { FaCheck, FaDownload, FaUpload } from "react-icons/fa";

export const UploadCSV = ({dimTable}) => {
    const [upLoadedFile, setUploadedFile] = useState(null);

    const [showPreview, setShowPreview] = useState(false);
    const [previewTable, setPreviewTable] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [numColumns, setNumColumns] = useState();

    const [showImportBtn, setShowImportBtn] = useState(false);

    const token = useGetToken();
    const templateUrl = `https://azdbiadminprdappsa.blob.core.windows.net/ticket-admin-portal/${dimTable}_Template.csv`

    const handleFileChange = (e) => {
            const selectedFile = e.target.files[0];
            setUploadedFile(selectedFile);
            setShowImportBtn(true);
            handleUpload(selectedFile);
    };

    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        var response = await uploadDimTableCSV(formData, dimTable, false, token);
        console.log(response);

        if(response.data) {
            setPreviewTable(response.data);
            setTableHeaders(Object.keys(response.data[0]));
            setNumColumns(Object.keys(response.data[0]).length);
            setShowPreview(true);
        }
        
    }

    const handleSubmitRows = async () => {
        const formData = new FormData();
        formData.append('file', upLoadedFile);

        var response = await uploadDimTableCSV(formData, dimTable, true, token);

        if(response.data) {
            toast.success('Rows Uploaded!');
            window.location.reload();
        } else {
            toast.error(response.error.message);
        }
    }

    return (
        <div className="content-container">
            <div className="upload-import-row" style={{width: '100%', justifyContent: 'space-between'}}>                
                <input
                    type="file"
                    id="csvFileInput"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                />
                <label htmlFor="csvFileInput" className="upload-label"><FaUpload/> Upload & Preview CSV</label>
                    
                <div style={{display: 'flex', flexDirection: 'row', gridGap: '10px', alignItems: 'center'}}>
                    <a target="_blank" href={templateUrl} className="App-text" style={{textDecoration: 'none'}}> <FaDownload/> Download Template</a>
                    {showImportBtn &&
                        <div className="add-rows-btn" onClick={handleSubmitRows}>Add Rows <FaCheck/> </div>
                    }
                </div>


                


            </div>

            {showPreview &&
                <MapTable 
                    tableData={previewTable} 
                    tableHeaders={tableHeaders} 
                    cellWidth="180px" 
                    dimTable={dimTable} 
                    itemsPerPage={50}
                    canEditDimension={false}
                />
            }
        </div>
    );
}