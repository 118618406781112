import { useState } from "react";
import Loader from "../navigation/Loader";
import { Dropdown } from "../shared/Dropdown";
import '../styles/modal.css';
import { useGetToken } from "../hooks/custom-hooks";
import { reprocessRollUpTable } from "../services/dimension-service";
import toast from "react-hot-toast";

export const ReprocessPopup = (props) => {

    const [selectedYear, setSelectedYear] = useState(props.currentYear);
    const token = useGetToken();

    function handleYearChange(event) {
        let dropdown = event.target;
        let year = dropdown.value;

        setSelectedYear(year);
    }

    const handleCompleteAction = () => {
        const reprocessData = async () => {
            let response = await reprocessRollUpTable(selectedYear, token);

            if(response.data) {
                toast.success(`${selectedYear} reprocessing has been started.`);
            }
        }
        reprocessData();
    }

    if(!props.isVisible) {
        return null;
    }

    return(
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <div>Reprocess Year</div>
                    </div>
                    <div className="modal-body">
                        <Dropdown 
                            id="dimTablesYearDropdown" 
                            value={selectedYear}
                            dropdownValues={props.yearDropdownList} 
                            handleDropdownChange={handleYearChange} 
                            label="Select Year to Reprocess: " 
                            className="form-fieldgroup"
                            defaultPhrase={null}
                        />
                    </div>
                    <div className="modal-footer">
                        <button className="button btnCancel" onClick={props.handleCancelAction}>Cancel</button>
                        <button className="button btn1" onClick={handleCompleteAction}>Go</button>
                    </div>
                </div>
            </div>
        )
}