import { FaTimes } from "react-icons/fa";
import Loader from "../navigation/Loader";
import '../styles/modal.css';

export const InfoPopup = ({isVisible, headerText, handleCancelAction}) => {
    if(!isVisible) {
        return null;
    }

    return(
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <div>{headerText}</div>
                    </div>
                    <div className="modal-body">
                        <span>This module shows the status of the dimension tables. Click on any row to view the Dimension tables data.</span>
                        <span>An error is defined as an issue with the dimension table. (ex. Missing row, or two rows with idential IDs).</span>
                        <span>A conflict is a an inssue within the ticketing data. One error can cause thousands of conflicts.</span>
                    </div>
                    <div className="modal-footer">
                        <button className="button btnCancel action-btn-row" onClick={handleCancelAction}>
                            <span>Close</span>
                            <FaTimes size={12} />
                        </button>
                    </div>
                </div>
            </div>
        )
} 