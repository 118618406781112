import { FaAngleDoubleRight, FaSync } from 'react-icons/fa';
import '../styles/Modules.css';
import { AreYouSureModal } from '../pop-ups/are-you-sure';
import { useState } from 'react';

export const PullingYears = ({currentYear, currentStatus}) => {
    const [showModal, setShowModal] = useState(false);

    const handleComplete = (event) => {
        event.stopPropagation();
        alert('test!');
    }

    return (
        // <div className='module-container-slim'>
        <div className='current-year-display'>
            <span className='current-year-header'>Data Retrieval Years</span>
            {currentStatus && 
                <div className='pulling-years-row'>
                    <span className='pulling-years-display'>{currentYear}</span>
                    <span className='pulling-years-display'>{currentYear+1}</span>
                </div>}
            {!currentStatus && <span className='current-year'>{currentYear}</span>}
        </div>
    );
}