import '../App.css';
import '../styles/DimTables.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import { downloadMissingDataCsv, generateMissingDataCsv, getDimTableErrorReport } from '../services/dimension-service';
import Loader from '../navigation/Loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaArrowLeft, FaPlus, FaEdit, FaDownload, FaHome } from 'react-icons/fa';
import { DuplicateKeyRows } from '../partials/DuplicateKeyRows';
import { MissingRowsTable } from '../partials/MissingRowsTable';
import { AddRowsPopup } from '../pop-ups/add-edit-rows';
import { useAccount, useMsal } from '@azure/msal-react';
import { LoadingTable } from '../shared/LoadingTable';
import { useGetToken } from '../hooks/custom-hooks';

export const FullPageErrorReport = () => {
    let { dimTable } = useParams();

    const [searchParams] = useSearchParams();
    const year = searchParams.get('year');
    const conflicts = searchParams.get('conflicts');
    const tableFocus = searchParams.get('tablePreview');

    const [showAddRowsPopup, setShowAddRowsPopup] = useState(false);

    const navigate = useNavigate();

    const [ticketErrors, setTicketErrors] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [missingTicketHeaders, setMissingTicketHeaders] = useState([]);
    const [missingTicketData, setMissingTicketData] = useState([]);

    const [missingPlanHeaders, setMissingPlanHeaders] = useState([]);
    const [missingPlanData, setMissingPlanData] = useState([]);

    const [dupeHeaders, setDupeHeaders] = useState([]);
    const [dupeData, setDupeData] = useState([]);

    const [isYearAgnostic, setIsYearAgnostic] = useState();
    const [hasTicketData, setHasTicketData] = useState();
    const [hasPlanData, setHasPlanData] = useState();

    const token = useGetToken();

    useEffect(() => {

        if(token !== null) {

            const getTicketErrors = async () => {
                let errors = await getDimTableErrorReport(dimTable, year, conflicts === null ? 0 : conflicts, token);
                
                console.log(errors.data);

                setTicketErrors(errors.data);
                setIsYearAgnostic(errors.data.isYearAgnostic);
                setHasPlanData(errors.data.hasPlanData);
                setHasTicketData(errors.data.hasTicketData);

                if(errors.data.missingTicketKeys && errors.data.missingTicketKeys.length > 0) {
                    setMissingTicketHeaders(Object.keys(errors.data.missingTicketKeys[0]));
                    setMissingTicketData(errors.data.missingTicketKeys);
                }

                if(errors.data.missingPlanKeys && errors.data.missingPlanKeys.length > 0) {
                    setMissingPlanHeaders(Object.keys(errors.data.missingPlanKeys[0]));
                    setMissingPlanData(errors.data.missingPlanKeys);
                }

                if(errors.data.duplicatedData && errors.data.duplicatedData.length > 0) {
                    setDupeHeaders(Object.keys(errors.data.duplicatedData[0]));
                    setDupeData(errors.data.duplicatedData);
                }         
                
                setIsLoading(false);
            }
            getTicketErrors();  
            
        }

    }, [token]);

    const handleHomeBtn = () => {
        navigate(`/`);
    }

    const handleDimTableLanding = () => {
        navigate(`/${dimTable}?year=${year}&conflicts=${conflicts}`)
    }
    
    const handleCsvClick = (ticketOrPlan) => {
        console.log('reached');
        const getCsv = async () => {
            let response = await generateMissingDataCsv(dimTable, year, ticketOrPlan, token);

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = `${dimTable}-missingData.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        getCsv();
    }

    if(isLoading) {
        return (
            <div className="App Full-Page">
            <div className="header-content">
                <span className='App-text action-row'>
                    <span className='back-btn' onClick={handleDimTableLanding}><FaArrowLeft/> {dimTable}</span>
                    <span className='back-btn' onClick={handleHomeBtn}><FaHome/> Home</span>
                </span>
            <div className="title-search-container" style={{display: 'flex', justifyContent: 'center'}}>

                <div className='loading-dimTable-title'></div>
                
                

                </div>
            </div>
            

            

            <div className="content-container" >
                <LoadingTable/>
            </div>
            
        </div>
        );
    }

    return (
        <div className='App Full-Page'>
            <span className='App-text action-row'>
                <span className='back-btn' onClick={handleDimTableLanding}><FaArrowLeft/> {dimTable}</span>
                <span className='back-btn' onClick={handleHomeBtn}><FaHome/> Home</span>
            </span>
            <span className='ticket-header'>{dimTable} Error Report</span>
            <Tabs defaultIndex={tableFocus}>
                <TabList>
                    {hasTicketData && <Tab>Missing Ticket Keys</Tab>}
                    {hasPlanData && <Tab>Missing Plan Keys</Tab>}
                    <Tab>Duplicated Data</Tab>
                </TabList>
                {hasTicketData && <TabPanel>
                    <div className='content-container'>
                        <span className='tab-description'>Below are a list of MajorMinorCodes with their cooresponding manifest that do not have a row in DimTicket for the {ticketErrors.seasonYear} season. This is resulting in <span style={{color: 'red'}}>{ticketErrors.totalConflicts}</span> affected tickets.</span>
                        {missingTicketData.length > 0 && 
                            <div className='icon-text-row missing-data' onClick={() => handleCsvClick('ticket')}>
                                <span>Download to CSV</span>
                                <FaDownload/>
                            </div>
                        }                        
                        <MissingRowsTable missingData={missingTicketData} dimTable={dimTable} />
                    </div>                        
                </TabPanel>}
                {hasPlanData && <TabPanel>
                    <div className='content-container'>
                        <span className='tab-description'>Below are a list of MajorMinorCodes with their cooresponding manifest that do not have a row in DimTicket for the {ticketErrors.seasonYear} season. This is resulting in <span style={{color: 'red'}}>{ticketErrors.totalConflicts}</span> affected plans.</span>
                        {missingPlanData.length > 0 &&
                            <div className='icon-text-row missing-data' onClick={() => handleCsvClick('plan')}>
                                <span>Download to CSV</span>
                                <FaDownload/>
                            </div> 
                        }      
                        <MissingRowsTable missingData={missingPlanData} missingHeaders={missingPlanHeaders} />
                    </div>                        
                </TabPanel>}
                <TabPanel>
                    <div className='content-container'>
                        <span className='tab-description'>Below are a list of rows that have identical identifying data. These conflicts will result in the duplication of rows in FactTicketSales and FactPlansSales with different TicketKeys.</span>
                        <DuplicateKeyRows dupeData={dupeData} dupeHeaders={dupeHeaders} year={year} dimTable={dimTable}/>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}