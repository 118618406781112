import '../App.css';
import '../styles/Modules.css';
import { FaCircle } from "react-icons/fa";

export const LoadingHistory = () => {
const loadingLines = Array.from({ length: 3 }, (_, index) => (
        <div className='loading-line'></div>
    ));

    return (
        <div className='module-container dim-dashboard view-history-module loading-state test-bg' style={{overflow: 'hidden'}}>
                <div className='App-text dashboard-header header-icon-row'>
                    <div className='loading-line header-line'></div>
                    <div style={{display:'flex', flexDirection: 'row', flexBasis: '20%', justifyContent: 'flex-end', gridGap: '10px'}}>
                        <FaCircle size={25} color='#5555558c'/>
                    </div>
                </div>

                 <div className='loading-container'>
                    {loadingLines}
                </div>
               
            </div>
    )
}