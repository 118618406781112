export const MapMagnets = (props) => {

    const handleClick = (missingData) => {

       const prefilledValues = Object.entries(missingData).map(([columnName, presetValue]) => ({
            columnName,
            presetValue
        }));

       props.onClickAction(prefilledValues);
    }

    return (
        <div className="magnet-container">
            {props.magnetData.map((item, index) => (
                <div className="data-magnets" key={index} onClick={() => handleClick(item)}>
                    {Object.entries(item).map(([key, value]) => (
                            <div key={key}>
                                <span className="info-display headerText">{key.charAt(0).toUpperCase()+key.substring(1,key.length)}</span>
                                <span className="info-display">{value === '' || value === null ? 'n/a' : value}</span>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
}