import { useEffect, useState } from 'react';
import '../App.css';
import '../styles/Modules.css';
import '../styles/donut-chart.css';
import { MapArray } from './MapArray';
import {FaInfoCircle, FaExclamationCircle, FaCheckCircle, FaExclamationTriangle, FaCircle, FaRedo, FaRunning, FaSpinner } from 'react-icons/fa';
import { AreYouSureModal } from '../pop-ups/are-you-sure';
import { FactTableErrorReport } from './FactTableErrorReport';
import { getLogicAppApiMonitor, getRunHistory, getSingleLogicAppRun } from '../services/dimension-service';
import DonutChart from 'react-donut-chart';
import Loader from '../navigation/Loader';
import { useNavigate } from "react-router-dom";
import { InfoPopup } from '../pop-ups/info-popup';
import { useAccount, useMsal } from '@azure/msal-react';
import { SingleRunPopUp } from '../pop-ups/single-run-info';
import toast from 'react-hot-toast';
import { useGetToken } from '../hooks/custom-hooks';


export function ViewLastRunHistory(props) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [runHistoryList, setRunHistoryList] = useState([]);
    const [showFullRunInfo, setShowFullRunInfo] = useState(false);
    const [showManualRunStatus, setShowManualRunStatus] = useState(false);
    const [singleRunInfo, setSingleRunInfo] = useState(null);

    const token = useGetToken();
    
    useEffect(() => {
        if(token !== null) {
            const getLastRunHistory = async () => {
                let response = await getRunHistory(token);

                if(response.data) {
                    setRunHistoryList(response.data);
                    setIsLoading(false);
                }
                
            }
            getLastRunHistory();
        }        
    }, [token]);    

    const handleRefresh = () => {
        setIsLoading(true);
        const getLastRunHistory = async () => {
            let response = await getRunHistory(token);

            if(response.data) {
                setRunHistoryList(response.data);
                setIsLoading(false);
            }
            
        }
        getLastRunHistory();
    }

    const handleInfoPopup = (historyId) => {
        const getInfo = async () => {
            let response = await getSingleLogicAppRun(historyId, token);
            setSingleRunInfo(response.data);
            setShowFullRunInfo(true);
        }
        getInfo();        
    }

    const handleManualLogicAppRun = (id) => {
        setShowFullRunInfo(false);

        console.log(id);

        const callLogicAppMonitor = async () => {
            let response = await getLogicAppApiMonitor(id, token);

            console.log(response);            
        }
        callLogicAppMonitor();
    };

    const loadingLines = Array.from({ length: 3 }, (_, index) => (
        <div className='loading-line'></div>
    ));

    if(isLoading) {
        return (
            <div className='module-container dim-dashboard view-history-module loading-state test-bg'>
                <div className='App-text dashboard-header header-icon-row'>
                    <div className='loading-line header-line'></div>
                    <div style={{display:'flex', flexDirection: 'row', flexBasis: '20%', justifyContent: 'flex-end', gridGap: '10px'}}>
                        <FaCircle size={25} color='#5555558c'/>
                    </div>
                </div>

                 <div className='loading-container'>
                    {loadingLines}
                </div>
               
            </div>
        );
    }

    return (
        <div className='module-container dim-dashboard view-history-module'>
            <div className='App-text dashboard-header header-icon-row'>
                <span>Data Retrieval Run History</span> 
                <div className='title-row-dropdown-info'>
                    <div className='run-history-status healthy-status'>
                    </div>
                    <FaRedo size={15} title='Refresh Run History' style={{cursor: 'pointer', width: '50px'}}
                        onClick={handleRefresh}/>
                </div>
            </div>
            <div className='table-header-row'>
                <span className='dimTable-data dimTable-header startTime'>Time of Run</span>
                <span className='dimTable-data dimTable-header interval'>Interval</span>
                <span className='dimTable-data dimTable-header export-name'>Export</span>
                <span className='dimTable-data dimTable-header run-success'></span>
            </div>
            <div className='scrollable-container'>
                {runHistoryList.map((item, index) => (
                    <div className='list-line runHistory-formatting' key={index}
                        onClick={() => handleInfoPopup(item.historyId)}>
                        <span className='startTime'>{item.startTimeFormatted}</span>
                        <span className='interval'>{item.fullIntervalFormatted}</span>
                        <span className='export-name'>{item.exportName.toUpperCase()}</span>
                        <span  className='run-success'>
                            {!item.completed && 
                                <Loader color='#ffe200'/>
                            }
                            
                            {item.completed && item.success &&
                                <FaCheckCircle color='green' title='Completed'/>
                            }

                            {item.completed && !item.success &&
                                <FaExclamationCircle color='red' title='Failed'/>
                            }    
                        </span>
                    </div>
                ))}
            </div>
            <SingleRunPopUp 
                runInfo={singleRunInfo}
                isVisible={showFullRunInfo} 
                handleCancelAction={() => setShowFullRunInfo(false)} 
                onCompleteAction={handleManualLogicAppRun}
            />
        </div>

        
        
    );
}