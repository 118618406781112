import { FaArrowRight } from "react-icons/fa";
import '../styles/ticket-ops-content.css';
import { useGetData } from "../hooks/custom-hooks";
import { getBlobUri } from "../services/blob-service";

export const PdfViewer = ({blobProperties}) => {

    console.log(blobProperties);

    return (
        <div className="content-container" style={{height:'100%'}}>
            {blobProperties && 
                <div className="bannerIframeOuter">
                    <a 
                        href={blobProperties.blobUri} 
                        target="_blank" 
                        className="bannerDescription"
                    >
                        <span className="bannerDescriptionText">
                            {blobProperties.blobName}
                        </span>
                    </a>
                    <iframe 
                        className="bannerIframe" 
                        src={blobProperties.blobUri}>
                    </iframe>
                </div>
            }
            {!blobProperties && 
                <div className="App-text">Upload a file to preview.</div>
            }
        </div>        
    );
}