import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PdfViewer } from "../partials/pdfViewer";
import { TicketOpsAdminFiles } from "../partials/ticketOps-admin-files";
import { useEffect, useState } from "react";
import { useGetData } from "../hooks/custom-hooks";
import { getAllBlobs } from "../services/blob-service";
import Loader from "../navigation/Loader";
import { LoadingTable } from "../shared/LoadingTable";


export const TicketOpsAdmin = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const allblobs = useGetData(getAllBlobs);

    const [selectedBlob, setSelectedBlob] = useState(null);

    useEffect(() => {
        if(allblobs !== null) {
            setLoading(false);
            setSelectedBlob(allblobs[0]);
        } else {
            setLoading(true);
        }
    }, [allblobs]);
    
    const handleBackBtn = () => {
        navigate(`/`);
    }

    const handlePreviewDocChange = (blobProperties) => {
        setSelectedBlob(blobProperties);
    }

    if(loading) {
        return (
            <div className="App Full-Page">
                <LoadingTable/>
            </div>            
        );
    }

    return (
        <div className="App Full-Page">
            <div className="header-content">
                <div className="landing-page-actions">
                    <span className='App-text back-btn' onClick={handleBackBtn}><FaHome/> Home</span>
                </div>
                <span className='ticket-header' style={{fontSize:'35px'}}>TicketOps Content Manager</span>
            </div>
            <div style={{display:'flex', flexDirection:'row',width:'100%', gridGap:'5%', height:'100%', overflow: 'hidden'}}>
                    <PdfViewer blobProperties={selectedBlob}/>
                    <TicketOpsAdminFiles 
                        allBlobs={allblobs}
                        handlePreviewDocChange={handlePreviewDocChange}
                    />
            </div>
        </div>
    );
}