import { useEffect, useState } from "react";
import { getBIParams, getSingleYearDimTables, sendDimDataToClone } from "../services/dimension-service";
import '../styles/DimTables.css';
import { Dropdown } from "../shared/Dropdown";
import Loader from "../navigation/Loader";
import { MapTable } from "../shared/MapTable";
import { useAccount, useMsal } from "@azure/msal-react";
import { LoadingTable } from "../shared/LoadingTable";
import { useGetToken } from "../hooks/custom-hooks";

export const CloneYear = ({ dimTable, selectedYear }) => {

    const [yearToView, setYearToView] = useState(selectedYear);
    const [nextYearToAddData, setNextYearToAddData] = useState();
    const [currentYear, setCurrentYear] = useState();
    const [yearDropdownOptions, setYearDropdownOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dimTableData, setDimTableData] = useState([]);
    const [dimTableHeaders, setDimTableHeaders] = useState([]);
    const [isYearAgnostic, setIsYearAgnostic] = useState(false);
    const [canEditDimension, setCanEditDimension] = useState(true);
    const [dataToClone, setDataToClone] = useState([]);

    const token = useGetToken();

    useEffect(() => {
        
        if(token !== null) {
            const getYearDropdownData = async () => {
                let paramResponse = await getBIParams(token);
                let rowResponse = await getSingleYearDimTables(dimTable, selectedYear, true, token);

                console.log(paramResponse);
                console.log(rowResponse);

                //setCurrentYear(response.data.seasonYear);
                setYearDropdownOptions(paramResponse.data.yearDropDownList);
                setNextYearToAddData(paramResponse.data.nextYearToAddData);
                setCurrentYear(paramResponse.data.seasonYear);
                setIsYearAgnostic(rowResponse.data.isYearAgnostic);
                setCanEditDimension(rowResponse.data.canEditDimension);

                if(rowResponse.data.singleYearData) {
                    setDimTableData(rowResponse.data.singleYearData);
                    
                    if(Object.values(rowResponse.data.singleYearData).length > 0) {
                        setDimTableHeaders(Object.keys(rowResponse.data.singleYearData[0]));
                    }
                    
                    setIsLoading(false);
                }
            } 
            getYearDropdownData();
        }

    }, [token]);


    function handleYearChange(event) {
        let dropdown = event.target;
        let year = dropdown.value;

       
        setYearToView(year);
        setIsLoading(true);

        const getDimTableData = async () => {
            let rowResponse = await getSingleYearDimTables(dimTable, year, true, token);
            console.log(rowResponse);

            if(rowResponse.data) {
                setDimTableData(rowResponse.data.singleYearData);
                
                if(Object.values(rowResponse.data.singleYearData).length > 0) {
                        setDimTableHeaders(Object.keys(rowResponse.data.singleYearData[0]));
                    }
                    
                    setIsLoading(false);
            }
        }
        getDimTableData(); 
               
    }

    const handleCloneData = (dataToSend) => {

        let copyToClone = dataToSend.filter((item) => item.isChecked === true);
        console.log(copyToClone);

        setDataToClone(copyToClone);
    }

    const submitDataToClone = () => {
        const sendDataToClone = async () => {
            let response = await sendDimDataToClone(dimTable, dataToClone, token);
            if(response.data) {
                alert('success');
            }
        }
        sendDataToClone();
    }

    if(isLoading) {
        return(
            <LoadingTable/>
        );
    }

    return(
        <div className="content-container">

            <div className="clone-header-row">
                <div style={{alignSelf:'center', display: 'flex', flexDirection: 'row', gridGap: '10px'}}>
                    <Dropdown 
                        id="dimTablesYearDropdown" 
                        value={yearToView}
                        dropdownValues={yearDropdownOptions} 
                        handleDropdownChange={handleYearChange} 
                        label="Select Year to Clone: " 
                        className="form-fieldgroup"
                        defaultPhrase={null}
                    />

                    <button className="button btnCancel" onClick={submitDataToClone}>Clone Data to {nextYearToAddData}</button>
                </div>
            </div>

            <MapTable 
                tableHeaders={dimTableHeaders} 
                tableData={dimTableData} 
                cellWidth="180px" 
                dimTable={dimTable} 
                itemsPerPage={50}
                height="32vh"
                canEditDimension={canEditDimension}
                isCloningProcess={true}
                yearToAddData={nextYearToAddData}
                syncTableData={handleCloneData}
            />

        </div>
    );
}