import React, { createRef, useEffect, useRef, useState } from "react";
import { EditableCells } from "../partials/EditableCells";

export const MapRow = (props) => {

    const classes = props.isCloningProcess && props.rowCells.isChecked ? 
            "data-dimTable-row wide-data to-clone" :  props.isCloningProcess && !props.rowCells.isChecked ?
                 "data-dimTable-row wide-data unselected" : "data-dimTable-row wide-data";
    
    const length = props.rowCells.length;
    const refs = useRef(Object.entries(props.rowCells).map(() => React.createRef()));

    function dontMap(columnName) {
        if(columnName === 'keyValue' || columnName === 'isChecked' || columnName === 'keyColumnName') {
            return false;
        }

        return true;
    }

    return (
        <div
            className={classes}
            key={props.index}
            data-include={props.isChecked}
        >
            {props.isCloningProcess && 
                <input style={{width: '35px', display: 'flex', justifyContent: 'flex-start', cursor: 'pointer'}} type="checkbox" checked={props.rowCells.isChecked} onChange={props.handleCheckboxChange}/>
            }
            {Object.entries(props.rowCells).map(([key, value]) => (
                <>
                {dontMap(key) && 
                    <EditableCells
                        ref={React.createRef()}
                        token={props.token}
                        cellWidth={props.cellWidth} 
                        cellValue={value}
                        columnName={key}
                        rowKey={props.rowCells.keyValue} 
                        isKeyValue={value === props.rowCells.keyValue}
                        dimTable={props.dimTable}
                        keyRowName={props.rowCells.keyColumnName}
                        isCloningProcess={props.isCloningProcess}
                        canEditDimension={props.canEditDimension}
                        // handleInputChange={props.handleInputChange}
                        handleOpenEditableContainer={props.handleOpenEditableContainer}
                        handleCancel={props.handleCancel}
                        handleChangeSubmit={props.handleChangeSubmit}
                        handleCellInputChange={props.handleCellInputChange}
                        handleInputDropdownFocus={props.handleInputDropdownFocus}
                    />
                }
                </>
            ))}
        </div>
    );
}