import { useState } from 'react';
import '../styles/ticket-ops-content.css';
import { FaEye, FaTrash, FaUpload } from 'react-icons/fa';
import { deleteBlob, uploadNewBlob } from '../services/blob-service';
import { useGetData, useGetToken } from '../hooks/custom-hooks';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export const TicketOpsAdminFiles = ({allBlobs, handlePreviewDocChange}) => {

    const [isHovered, setIsHovered] = useState(false);
    const [showImportBtn, setShowImportBtn] = useState(false);
    const [upLoadedFile, setUploadedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const token = useGetToken();

    const handlePreviewLink = (blobProperties) => {
        handlePreviewDocChange(blobProperties);
    }

    const handleFileChange = (e) => {
            const selectedFile = e.target.files[0];
            setUploadedFile(selectedFile);
            setShowImportBtn(true);
            handleUpload(selectedFile);
    };

    const handleUpload = async (file) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        var response = await uploadNewBlob(formData, token);
        console.log(response);

        if(response.data) {
            toast.success('Content Uploaded!');
            setLoading(false);
            navigate('/TicketOpsAdmin');
        }
        
    }

    const handleDelete = (blobName) => {
        setLoading(true);

        let response = deleteBlob({
            accessToken: token,
            params: { blobName }
        });

        if (response.data) {
            toast.success('File Deleted');
            setLoading(false);
            navigate('/TicketOpsAdmin');
        } else {
            toast.error('Error deleting file');
            setLoading(false);
            navigate('/TicketOpsAdmin');
        }
    }

    if(Object.entries(allBlobs).length === 0) {
        return (
            <div className='content-container' style={{height:'100%'}}>
                <input
                    type="file"
                    id="csvFileInput"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                />
                <label 
                    htmlFor="csvFileInput" 
                    className='pdf-link-btn'
                    style={{width:'-webkit-fill-available'}}
                >
                    <FaUpload/> Upload New Content
                </label>
            </div>     
        )
    }

    return (
        <div className='content-container' style={{height:'100%'}}>
            <div>
                <span className='App-text'>Manage Files</span>
            </div>
            <div className="ticketOps-btn-container">
                {allBlobs.map((item, index) => (
                    <div
                        key={index}
                        className='pdf-link-btn' 
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {item.blobName}
                        {isHovered && 
                            <div className='action-buttons'>
                                <button 
                                    className='action-btn preview-btn' 
                                    onClick={() => handlePreviewLink(item)}
                                >
                                    <FaEye/>
                                    <span>Preview</span>
                                </button>
                                <button 
                                    className='action-btn delete-btn' 
                                    onClick={() => handleDelete(item.blobName)}>
                                    <FaTrash/>
                                    <span>Delete</span>
                                </button>
                            </div>
                        }
                    </div>
                ))}
            </div>
            <input
                type="file"
                id="csvFileInput"
                accept=".pdf"
                onChange={handleFileChange}
                style={{display: 'none'}}
            />
            <label 
                htmlFor="csvFileInput" 
                className='pdf-link-btn'
                style={{width:'-webkit-fill-available'}}
            >
                <FaUpload/> Upload New Content
            </label>
        </div>            
    );
}