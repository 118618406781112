import React, { useState, useEffect } from "react";

export const Dropdown = ({className, id, label, value, dropdownValues, handleDropdownChange, color = 'white', defaultPhrase = 'Select One', handleValueLocally = false}) => {
        
    const [localValue, setLocalValue] = useState(null);


    const dropdownOptions = dropdownValues.map(d => 
        <option data-customvalue1={d.customvalue1} key={d.id} value={d.id}>
            {d.name}
        </option>
    )

    return (
        <div className={className && className}>
            {label && 
                <label htmlFor={id} style={{color: color}}>{label}</label>
            }
            <select id={id} value={handleValueLocally ? localValue : value} onChange={handleDropdownChange}>
                {defaultPhrase && <option>{defaultPhrase}</option>}
                {dropdownOptions}
            </select>
        </div>
    )
}