import '../App.css';
import '../styles/loading-states.css';
import { CustomInterval } from '../partials/CustomInterval';
import { DimTablesPeek } from '../partials/DimTablesPeek';
import { ViewLastRunHistory } from '../partials/ViewLastRunHistory';
import { RollOverYear } from '../partials/RollOverYear';
import { ToggleTwoYears } from '../partials/ToggleTwoYears';
import { ReprocessRollUp } from '../partials/ReprocessRollUp';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../navigation/Loader';
import { getBIParams } from '../services/dimension-service';
import { getCurrentUser } from '../services/claims-service';
import { CurrentYear } from '../partials/CurrentYear';
import { PullingYears } from '../partials/PullingYears';
import { useAccount, useMsal } from '@azure/msal-react';
import { useGetToken } from '../hooks/custom-hooks';
import { LoadingModule } from '../partials/LoadingModule';
import { LoadingHistory } from '../partials/LoadingHistory';

export const HomePage = () => {

    const token = useGetToken();

    const [currentYear, setCurrentYear] = useState();
    const [stagingNextSeason, setStagingNextSeason] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [yearDropdownList, setYearDropdownList] = useState([]);

    useEffect(() => {

        if (token !== null) {
            const getBIVariables = async () => {

                let currentParams = await getBIParams(token);

                setCurrentYear(currentParams.data.seasonYear);
                setStagingNextSeason(currentParams.data.stageNextSeason);
                setYearDropdownList(currentParams.data.yearDropDownList);
                setIsLoading(false);
            }
            getBIVariables(); 
        }
    }, [token]);

    if(isLoading) {
        return(
            <div className='App'>
                <div className='app-row'>
                    <div className='half-screen-column'>
                        <LoadingModule/>
                    </div>
                    <div className='half-screen-column'>
                        <div className='module-container-biParams animate' style={{height: '93px', width: '100%', borderRadius: '5px'}}></div>                
                        <LoadingHistory/>
                        <div className='module-btn-row'>
                            <CustomInterval/>
                            <ReprocessRollUp 
                                currentYear={currentYear}
                                yearDropdownList={yearDropdownList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='App'>
            <div className='app-row'>
                <div className='half-screen-column'>
                    <DimTablesPeek seasonYear={currentYear} yearDropdownList={yearDropdownList}/>
                </div>
                <div className='half-screen-column'>
                    <div className='module-container-biParams'>
                        <CurrentYear currentYear={currentYear}/>
                        <PullingYears currentYear={currentYear} currentStatus={stagingNextSeason}/>                    
                    </div>                
                    <ViewLastRunHistory/> 
                    <div className='module-btn-row'>
                        <CustomInterval/>
                        <ReprocessRollUp 
                            currentYear={currentYear}
                            yearDropdownList={yearDropdownList}
                        />
                    </div>
                </div>
            </div>
        </div>        
    );
}