import { useAccount, useMsal } from '@azure/msal-react';
import React, { lazy, useEffect, useRef, useState } from 'react';
import { getAddFormDropdownOptions } from '../services/dimension-service';
import { overrideEndpoints } from '../endpoint-utility';

const InputDropdown = ({ className, dimTable, fieldParams, handleInputChange, handleDropdownOption, handleFocus,
                            disabled = false, token, dropdownClassNames = 'input-dd-options' }) => 
{
    
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDropdownOptions = async () => {

      console.log(fieldParams);     

      let response = [];
      if(fieldParams.overrideDropdownValues !== null) {
        console.log(fieldParams.overrideDropdownValues);
        console.log(overrideEndpoints);
        const overrideName = overrideEndpoints.find((override) => override.overrideValue.toLowerCase() === fieldParams.overrideDropdownValues.toLowerCase()).serviceCall;          
        response = await overrideName({searchValue: fieldParams.currentValue}, token);

        console.log(response);
      }
      else {
          response = await getAddFormDropdownOptions({
            dimTable: dimTable,
            columnName: fieldParams.columnName,
            filteredText: fieldParams.currentValue ?? '',
            keyColumnName: fieldParams.includeKeyValues ? fieldParams.keyColumnName : null
        }, token);
      }

      setDropdownOptions(response.data);
      setIsLoading(false);
    }
    getDropdownOptions();
  }, [fieldParams]); 

  const dropdownRef = useRef(null);

  const handleDropdownSelection = (selectedOption) => {
    handleDropdownOption(selectedOption, fieldParams.columnName);
  }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsInputFocused(false);
  //     }
  //   };

  //   // Attach the event listener when the component mounts
  //   document.addEventListener('click', handleClickOutside);

  //   // Detach the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  
  useEffect(() => {
    console.log(dropdownOptions);
  }, [fieldParams]);


  if(loading) {
    return null;
  }

  return (
    <div className={className} ref={dropdownRef}>
      <input
        type="text"
        className='data-entry'
        value={fieldParams.displayValue ?? fieldParams.currentValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        disabled={disabled}        
        placeholder={fieldParams.placeholder}
        id={fieldParams.columnName+'-input'}
      />

      {/* Display the filtered options in a scrollable list only when the input is focused */}
      {fieldParams.isFocused && dropdownOptions && dropdownOptions.length > 0 && (
        <div className={dropdownClassNames}>
            {!fieldParams.includeKeyValues ?
              dropdownOptions.map((option, index) => (
                <div className='dd-options' key={index} onClick={() => handleDropdownSelection(option)}>
                  {option}
                </div>
            ))
            : dropdownOptions.map((option, index) => (
                <div className='dd-options' key={index} onClick={() => handleDropdownSelection(option)}>
                  {option.displayText}
                </div>

            ))}
        </div>
      )}
    </div>
  );
};

export default InputDropdown;
