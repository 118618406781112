import { callExternalApi } from "./api-service";
const apiServerUrl = process.env.REACT_APP_BASE_API_URL;

export const getAllBlobs = async (queryConfig) => {
  const config = {
    url: `${apiServerUrl}/GetAllTicketOpsBlobs`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${queryConfig.accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const uploadNewBlob = async (formData, accessToken) => {

  const config = {
    url: `${apiServerUrl}/UploadNewBlobContent`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };
}

export const deleteBlob = async (queryConfig) => {
    const config = {
        url: `${apiServerUrl}/DeleteBlob`,
        method: 'GET',
        params: queryConfig.params,
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        },
        data: null
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
}

export const getBlobUri = async (queryConfig) => {
    const config = {
        url: `${apiServerUrl}/GetBlobUri`,
        method: 'GET',
        params: queryConfig.params,
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        },
        data: null
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
}