import { useNavigate } from "react-router-dom";
import Loader from "../navigation/Loader";
import '../styles/modal.css';
import { AddRows } from "../partials/AddRows";

export const AddRowPopup = ({isVisible, dimTable, onCancelAction, presetValues}) => {
    if(!isVisible) {
        return null;
    }

    console.log(presetValues);

    return(
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <span>Add Rows</span>
                    </div>
                    {/* <div className="modal-subtitle">
                        <span>{dimTable}</span>
                    </div> */}
                    <div className="modal-body modal-body-scrollable">
                        <AddRows dimTable={dimTable} preFilledValues={presetValues}/>
                    </div>
                    <div className="modal-footer">
                        <button className="button btnCancel" onClick={onCancelAction}>Cancel</button>
                        {/* <button className="button btn1" onClick={handleCompleteAction}>
                            Go
                        </button> */}
                    </div>
                </div>
            </div>
        )
}