import { FaSync } from 'react-icons/fa';
import '../styles/Modules.css';
import { useState } from 'react';
import { ReprocessPopup } from '../pop-ups/reprocess-popup';

export function ReprocessRollUp(props) {
    const [showModal, setShowModal] = useState(false);

    console.log(props.yearDropdownList);

    const handleComplete = () => {
        alert('test!');
    }

    const showPopup = () => {
        setShowModal(true);
    }

    const handleCancelAction = (event) => {
        event.stopPropagation();
        setShowModal(false);
    }

    return (
        // <div className='module-container-slim'>
        //     <div style={{width: '100%', height: '100%', justifyContent: 'center', display: 'flex'}}
        //         onClick={() => setShowModal(true)}>
        //         <div className="icon-text-row App-text"><FaSync className='teal-icons'/> Reprocess Roll Up</div>           
        //     </div>

        //     <AreYouSureModal onCompleteAction={handleComplete} handleCancelAction={() => setShowModal(false)} loadingState={false} isVisible={showModal} 
        //         bodyText={'Are you sure you want to roll over the year? This will roll over the year from 2023 to 2024.'}/>
        // </div>

        <div className="module-container-slim" onClick={showPopup}>
            <div className="icon-text-row App-text">
                <div className="icon-text-row App-text"><FaSync className='teal-icons'/> Reprocess Roll Up</div>
            </div>

            <ReprocessPopup 
                onCompleteAction={handleComplete} 
                handleCancelAction={handleCancelAction} 
                loadingState={false} 
                isVisible={showModal} 
                currentYear={props.currentYear}
                yearDropdownList={props.yearDropdownList}
            />
        </div>  
    );
}