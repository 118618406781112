import { callExternalApi } from "./api-service";

const apiServerUrl = process.env.REACT_APP_BASE_API_URL;

export const getDimensionsBySeason = async (queryConfig) => {

  const config = {
    url: `${apiServerUrl}/GetDimensionsBySeason`,
    method: "GET",
    params: queryConfig.params,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${queryConfig.accessToken}`,
    },
    data: null
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getDimTableErrorReport = async (table, year, conflicts, accessToken) => {
  
  const config = {
    url: `${apiServerUrl}/Get${table}ErrorReport?seasonYear=${year}&conflictCount=${conflicts}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getDimTableColumns = async (dimTableName, accessToken) => {

  const config = {
    url: `${apiServerUrl}/GetDimTableColumnNames?dimTableName=${dimTableName}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const addDimTableRow = async (dimTableName, jsonToSend, accessToken) => {
  const config = {
    url: `${apiServerUrl}/AddRow_${dimTableName}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: jsonToSend
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const uploadDimTableCSV = async (formData, dimTable, insertToDb, accessToken) => {

  const config = {
    url: `${apiServerUrl}/UploadCSV_${dimTable}?insertRows=${insertToDb}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };
}

export const getBIParams = async (accessToken) => {

  const config = {
    url: `${apiServerUrl}/GetBiVariables`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getSingleYearDimTables = async (dimTable, year, includeKeys, accessToken) => {

  const config = {
    url: `${apiServerUrl}/GetSingleYear_${dimTable}?seasonYear=${year}&includeKeys=${includeKeys}`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getRunHistory = async (accessToken) => {

  const config = {
    url: `${apiServerUrl}/GetLastDayRunHistory`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getEmptyDimTableObject = async (queryConfig) => {
  const config = {
    url: `${apiServerUrl}/GetDimTableFields`,
    method: 'GET',
    params: queryConfig.params,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${queryConfig.accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getAddFormDropdownOptions = async (queryParams, accessToken) => {
  const config = {
    url: `${apiServerUrl}/GetAddFormDropdownOptions`,
    params: queryParams,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getFilteredEmployees = async (queryParams, accessToken) => {
  const config = {
    url: `${apiServerUrl}/GetFilteredEmployees`,
    params: queryParams,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getDimTableAddRequirements = async (queryConfig) => {
  const config = {
    url: `${apiServerUrl}/GetDimTableAddVariables`,
    method: 'GET',
    params: queryConfig.params,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${queryConfig.accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const updateSingleCellDimTable = async (dimTable, columnName, newValue, rowKey, keyRowName, accessToken) => {
  const config = {
    url: `${apiServerUrl}/UpdateSingleCellDimTable`,
    method: 'POST',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      dimTable: dimTable,
      columnName: columnName,
      newValue: newValue,
      rowKey: rowKey,
      keyRowName: keyRowName
    }
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getSingleLogicAppRun = async (historyId, accessToken) => {
  const config = {
    url: `${apiServerUrl}/GetSingleLogicAppRun?historyId=${historyId}`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const triggerLogicAppRun = async (dataToSend, isRerun, accessToken) => {
  const config = {
    url: `${apiServerUrl}/TriggerApiCall?isRerun=${isRerun}`,
    method: 'POST',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: dataToSend
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}


export const sendDimDataToClone = async (dimTable, dataToSend, accessToken) => {
  const config = {
    url: `${apiServerUrl}/Clone${dimTable}`,
    method: 'POST',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: dataToSend
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getLogicAppApiMonitor = async (instanceId, accessToken) => {
  const config = {
    url: `${apiServerUrl}/MonitorLogicAppTrigger?instanceId=${instanceId}`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const getLogicAppCurrentStatus = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/GetLogicAppCurrentStatus`,
    method: 'GET',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const reprocessRollUpTable = async (year, accessToken) => {
  const config = {
    url: `${apiServerUrl}/ReprocessRollUpTableByYear?year=${year}`,
    method: 'POST',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const resolveDuplicates = async (dimTable, year, accessToken) => {
  const config = {
    url: `${apiServerUrl}/ResolveDuplicates?seasonYear=${year}&dimTableName=${dimTable}`,
    method: 'POST',
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  }

  const { data, error } = await callExternalApi({ config });

  return { 
    data: data || null,
    error
  }
}

export const generateMissingDataCsv = async (dimTable, year, ticketOrPlan, accessToken) => {
  const url = `${apiServerUrl}/${dimTable}_DownloadMissingData?seasonYear=${year}&missingTable=${ticketOrPlan}`;
  
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);

  const options = {
    method: 'GET', // or 'POST', 'PUT', etc.
    headers: headers,
    // Other options like body, mode, etc. can be added here
  };

  const response = await fetch(url, options);

  if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

  return blob;
}