import { useEffect, useState } from "react";
import { getCurrentUser } from "../services/claims-service";
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from "../navigation/Loader";
import '../styles/DimTables.css';
import { useGetToken } from "../hooks/custom-hooks";

export const CurrentUser = () => {

    const token = useGetToken();

    const [name, setName] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(token !== null) {
            const getCurrentUserInfo = async () => {
                let response = await getCurrentUser(token);

                console.log(response.data);
                setName(response.data);
                setLoading(false);
            }
            getCurrentUserInfo();
        }
    }, [token]);

    if(loading) {
        return(
            <div className="user-name"></div>
        )
    };

    return (
        <>
            {name &&
                <span className='current-user'>{name}</span>
            }
            {!name &&
                <span className='current-user'>Doe, John</span>
            }
        </>
        
    );
}