import '../App.css';
import '../styles/Modules.css';
import { FaCircle } from "react-icons/fa";

export const LoadingModule = () => {

    const loadingLines = Array.from({ length: 6 }, (_, index) => (
        <div className='loading-line'></div>
    ));
    
    return (
        <div className='module-container dim-dashboard loading-state test-bg'>
                <div className='App-text dashboard-header header-icon-row'>
                    <div className='loading-line header-line'></div>
                    <div style={{display:'flex', flexDirection: 'row', flexBasis: '20%', justifyContent: 'flex-end', gridGap: '10px'}}>
                        <div className='loading-line loading-dd'></div>
                        <FaCircle size={25} color='#5555558c'/>
                    </div>
                </div>

                <div className='table-header-row'>
                    <span className='dimTable-data dimTable-name loading-line columnName'></span>
                    <span className='dimTable-data dimTable-errorCount loading-line columnName'></span>
                </div>

                 <div className='loading-container' style={{overflow: 'hidden'}}>
                    {loadingLines}
                </div>
               
            </div>
    );
}