import { useEffect, useRef, useState } from "react"
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { updateSingleCellDimTable } from "../services/dimension-service";
import { useAccount, useMsal } from "@azure/msal-react";
import InputDropdown from "./InputDropdown";

export const EditableCells = (props) => {
    //{ cellWidth, cellValue, columnName, rowKey, keyRowName, isKeyValue, dimTable, isCloningProcess, ref, canEditDimension = false }
    // const [isInputFocused, setIsInputFocused] = useState(false);
    // const [val, setVal] = useState(cellValue);

    const [editing, setEditing] = useState(false);
    const [currentVal, setCurrentVal] = useState(props.cellValue);

    const handleInputChange = (event) => {
        let newVal = event.target.value;
        setCurrentVal(newVal);
    }

    const handleCancel = () => {
        setCurrentVal(props.cellValue);
        setEditing(false);
    }


    const editTableData = () => {
        props.handleCellInputChange(currentVal, props.columnName, props.rowKey, props.keyRowName, !props.isCloningProcess);
    }

    const handleCloningChange = (event) => {
        let newVal = event.target.value;
        setCurrentVal(newVal);
        // props.handleCellInputChange(newVal, props.columnName, props.rowKey, props.keyRowName, !props.isCloningProcess);
    }

    const handleDropdownSelection = (selectedOption) => {
        props.handleCellInputChange(selectedOption, props.columnName, props.rowKey, props.keyRowName, !props.isCloningProcess);
    }

    const handleEditableField = () => {
        setEditing(true);
    }

    const fieldParams = {
        currentValue: currentVal,
        placeholder: props.placeholder,
        isFocused: editing,
        columnName: props.columnName
    };

    if(!props.canEditDimension) {
        return(
            <span 
                data-key={props.rowKey} 
                data-isKey={props.isKeyValue}
                data-columnName={props.columnName}
                className="data-dimTable-noEdit" 
                style={{width: props.cellWidth}}
            >
                {props.cellValue}
            </span>
        );
    }

    if(props.isKeyValue || props.columnName.toLowerCase().includes('year')) {
        return(
            <input style={{width: props.cellWidth}} className="data-dimTable disabled-input" disabled type="text" value={props.cellValue}/>
        );
    }

    return(
        <div style={{width: props.cellWidth, margin: '0px 5px'}}>
            { !props.isCloningProcess && !editing &&
                <span 
                    className="data-dimTable disabled-input" 
                    style={{width: '100%'}}
                    onClick={() => setEditing(!editing)}
                >
                    {props.cellValue}
                </span>
            }
            
            { !props.isCloningProcess && editing &&
                <div className="data-input-row">
                    <input className="data-dimTable edit-input" onChange={handleInputChange} style={{color: 'black'}} type="text" value={currentVal}/>
                    <FaRegTimesCircle size={20} color="red" onClick={handleCancel} style={{cursor: 'pointer'}}/>
                    <FaCheckCircle size={20} color="green" onClick={editTableData} style={{cursor: 'pointer'}}/>
                </div>
            }

            { props.isCloningProcess && editing &&
                <div 
                    className="data-input-row" 
                >
                    <InputDropdown
                        fieldParams={fieldParams}
                        dimTable={props.dimTable}
                        columnName={props.columnName}
                        className='clone-input-dropdown'
                        placeholder={currentVal}
                        dropdownClassNames="input-dd-options relative"
                        handleInputChange={handleCloningChange}
                        handleDropdownOption={handleDropdownSelection}
                        token={props.token}
                    /> 
                    <FaRegTimesCircle size={20} color="red" onClick={handleCancel} style={{cursor: 'pointer'}}/>
                    <FaCheckCircle size={20} color="green" onClick={editTableData} style={{cursor: 'pointer'}}/>
                    
                </div>
            }

            { props.isCloningProcess && !editing &&
                <div 
                    className="data-input-row" 
                >
                    <span 
                        className="data-dimTable disabled-input" 
                        style={{width: '100%'}}
                        onClick={handleEditableField}
                    >
                        {props.cellValue}
                    </span>
                    
                </div>
            }
        </div>
    );
}