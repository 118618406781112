import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import '../App.css';
import '../styles/DimTables.css';
import { MapMagnets } from "../shared/MapMagnets";
import { AddRows } from "./AddRows";
import { AddRowPopup } from "../pop-ups/add-edit-rows";

export function MissingRowsTable(props) {
    const isEmpty = props.missingData.length == 0;    

    const [showNewRowPopup, setShowNewRowPopup] = useState(false);
    const [newRowData, setNewRowData] = useState([]);

    const handleMissingDataClick = (prefilledValues) => {
        setNewRowData(prefilledValues);

        setShowNewRowPopup(true);
    }

    return (
        <>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden'}}>
                {!isEmpty &&
                    <div className="missing-key-container">
                        <MapMagnets 
                            magnetData={props.missingData} 
                            onClickAction={handleMissingDataClick}/>
                    </div>
                }

                {isEmpty &&
                    <span style={{fontSize: '30px'}}>NO CONFLICTS</span>
                }
            </div>
            
            <AddRowPopup 
                dimTable={props.dimTable}
                onCancelAction={() => setShowNewRowPopup(false)}
                presetValues={newRowData}
                isVisible={showNewRowPopup}
            />
        </>
    );
}