import { useNavigate } from "react-router-dom";
import { CurrentUser } from "./CurrentUser";
import { FaEllipsisH } from "react-icons/fa";
import { useState } from "react";

export const Banner = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const elipsesColor = isHovered ? '#3fc2cd': 'white';

    const handleElipsesHover = () => {
        setIsHovered(true);
    }

    const handleElipsesOut = () => {
        setIsHovered(false);
    }

    return (
        <div className="banner">
            <div className='banner-left' onClick={() => navigate('/')}>
                <img style={{width: '100px'}} src='https://appcontentimages.blob.core.windows.net/$web/snake-blackTeal.png'/>
                <span className='banner-header'>Ticket Admin Portal</span>
                <div 
                    className="collapsable-btns"
                    onMouseOver={(handleElipsesHover)}
                    onMouseOut={handleElipsesOut}
                >
                    <FaEllipsisH 
                        size={15} 
                        color={elipsesColor} 
                        style={{padding: '10px', borderRadius:'5px'}}
                    />
                    {isHovered &&
                        <>
                            <a className="collapsable-btn" href='/TicketOpsAdmin'>Box Office Training Admin</a>
                            <a className="collapsable-btn" target="_blank" href='https://tickettraining.dbacks.com/'>Box Office Training</a>
                        </>  
                    }
                    
                </div>
            </div>
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'flex-end', paddingRight:'40px'}}>
            <CurrentUser/>
            </div>        
        </div>        
    );
}