import { FaAngleDoubleRight, FaSync } from 'react-icons/fa';
import '../styles/Modules.css';
import '../styles/loading-states.css';
import { AreYouSureModal } from '../pop-ups/are-you-sure';
import { useState } from 'react';

export const CurrentYear = ({currentYear}) => {
    
    return (
        // <div className='module-container-slim'>
        <div className='current-year-display'>
            <span className='current-year-header'>Current BI Year</span>
            <span className='current-year'>{currentYear}</span>
        </div>
    );
}