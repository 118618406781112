import { useEffect, useState } from "react"
import { getLogicAppCurrentStatus, getSingleLogicAppRun, triggerLogicAppRun } from "../services/dimension-service";
import { useAccount, useMsal } from "@azure/msal-react";
import Multiselect from 'multiselect-react-dropdown';
import Datetime from 'react-datetime';
import '../styles/popups.css';

import "react-datetime/css/react-datetime.css";
import { json } from "react-router-dom";
import toast from "react-hot-toast";
import { InvalidEntries } from "./invalid-entries";
import { useGetToken } from "../hooks/custom-hooks";

export const SingleRunPopUp = ({runInfo, showInputs, isVisible, handleCancelAction, onCompleteAction, isApiRunningConst}) => {
    
    const currentYear = new Date().getFullYear();

    const [startMonth, setStartMonth] = useState(1);
    const [endMonth, setEndMonth] = useState(1);

    const [startDay, setStartDay] = useState(1);
    const [endDay, setEndDay] = useState(1);

    const [startYear, setStartYear] = useState(currentYear);
    const [endYear, setEndYear] = useState(currentYear);

    const [startHour, setStartHour] = useState(0);
    const [endHour, setEndHour] = useState(0);

    const [startMinute, setStartMinute] = useState(0);
    const [endMinute, setEndMinute] = useState(0);

    const [selectedExports, setSelectedExports] = useState([]);
    const [durableInstanceId, setDurableInstanceId] = useState(0);

    const [isApiRunning, setIsApiRunning] = useState(false);
    const [invalidEntries, setInvalidEntries] = useState([]);
    const [showInvalidEntries, setShowInvalidEntries] = useState(false);

    const options = [{name: 'Ticket', id: 1},{name: 'Cust', id: 2},{name: 'Plans', id: 2}];

    const token = useGetToken();

    let isArray = function(a) {
        return(!!a) && (a.constructor === Array);
    }

    
    useEffect(() => {
        const getRunStatus = async () => {
            let response = await getLogicAppCurrentStatus(token);

            console.log(response.data);
            setIsApiRunning(!response.data);            
        }
        getRunStatus();
    }, []);

    useEffect(() => {
        if(durableInstanceId !== 0) {
            onCompleteAction(durableInstanceId);
        }
    }, [durableInstanceId]);

    const resetSelectedValues = (selectedList, selectedItem) => {
        const exports = selectedList.map(item => item.name);
        setSelectedExports(exports);
    }

    const handleMonthSelection = (month, isStartInterval) => {
        if(isStartInterval) { setStartMonth(month); }
        else { setEndMonth(month); }

        console.log(month);
    }

    const handleDaySelection = (day, isStartInterval) => {
        if(isStartInterval) { setStartDay(day); }
        else { setEndDay(day); }
    }

    const handleYearChange = (year, isStartInterval) => {
        if(isStartInterval) { setStartYear(year); }
        else { setEndYear(year); }
    }

    const handleHourSelection = (hour, isStartInterval) => {
        if(isStartInterval) { setStartHour(hour); }
        else { setEndHour(hour) };
    }

    const handleMinuteSelection = (minute, isStartInterval) => {
        if(isStartInterval) { setStartMinute(minute); }
        else { setEndMinute(minute); }
    }

    const handleQueueRun = () => {
        const jsonToSend = {
            startMonth,
            startDay,
            startYear,
            startHour,
            startMinute,
            endMonth,
            endDay,
            endYear,
            endHour,
            endMinute,
            selectedExports
        };

        handleRun(jsonToSend);
    }

    const handleRun = (dataToSend = null) => {

        let jsonToSend = runInfo;
        let laInstanceId = 0;

        if(showInputs) {
            jsonToSend = dataToSend;
        }

        if(token) {
            const triggerRun = async () => {
                let response = await triggerLogicAppRun(jsonToSend, !showInputs, token);

                if(response.data) {
                    setDurableInstanceId(response.data);
                    toast.success('Pull Initiated.');
                } else {
                    if(isArray(response.error.message)) {
                        setInvalidEntries(response.error.message);
                        setShowInvalidEntries(true);
                    } else {
                        toast.error(response.error.message);
                    }
                } 
            }
            triggerRun();
        }        
    }

    const MonthDropdown = ({isStartInterval = false}) => {
    // Array of month names
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

        return (
            <select className="date-selector-dd" value={isStartInterval ? startMonth : endMonth} onChange={(event) => handleMonthSelection(event.target.value, isStartInterval)}>
            {/* Map through the months array to create option elements */}
            {months.map((month, index) => (
                <option key={index} value={index + 1}>{month}</option>
            ))}
            </select>
        );
    };

    const DayDropdown = ({ selectedMonth, isStartInterval = false }) => {
        const getDaysInMonth = (month, year) => {
            return new Date(year, month, 0).getDate();
        };

        const daysInMonth = Array.from({ length: getDaysInMonth(selectedMonth, 2024) }, (_, i) => i + 1);

        return (
            <select className="date-selector-dd" value={isStartInterval ? startDay : endDay} onChange={(event) => handleDaySelection(event.target.value, isStartInterval)}>
                {daysInMonth.map((day, index) => (
                <option key={index} value={day}>{day}</option>
                ))}
            </select>
        );
    };

    const HourDropdown = ({isStartInterval = false}) => {
        // Create an array for 24 hours
        const hours = Array.from({ length: 24 }, (_, i) => i);

        return (
            <select className="date-selector-dd" value={isStartInterval ? startHour : endHour} onChange={(event) => handleHourSelection(event.target.value, isStartInterval)}>
            {/* Map through the hours array to create option elements */}
            {hours.map((hour) => (
                <option key={hour} value={hour}>{hour}</option>
            ))}
            </select>
        );
    };

    const MinuteDropdown = ({isStartInterval = false}) => {
        // Create an array for 24 hours
        const minutes = Array.from({ length: 59 }, (_, i) => i);

        return (
            <select className="date-selector-dd" value={isStartInterval ? startMinute : endMinute} onChange={(event) => handleMinuteSelection(event.target.value, isStartInterval)}>
            {/* Map through the hours array to create option elements */}
            {minutes.map((min) => (
                <option key={min} value={min}>{min < 10 ? '0' + min.toString() : min}</option>
            ))}
            </select>
        );
    };

    if(!isVisible) {
        return null;
    }

    return (
        <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <div>{showInputs ? 'Run Custom Interval' : 'Run Information'}</div>
                    </div>
                    {showInputs && <span className="modal-subtitle">minimum 1 hour when running a custom interval.</span>}
                    <div className="modal-body centered" style={{width: 'maxContent'}}>
                        {!showInputs && <div className="label-info-row">
                            <span className="run-history-label">Process Name</span>
                            <span className="run-history-value">{runInfo.logicAppName}</span>
                        </div>}
                        <div className="label-info-row">
                            <span className="run-history-label">Export Name(s)</span>
                            {!showInputs && <span className="run-history-value">{runInfo.exportName.toUpperCase()}</span>}
                            {showInputs &&
                                <Multiselect
                                    options={options} // Options to display in the dropdown
                                    selectedValues={null} // Preselected value to persist in dropdown
                                    onSelect={resetSelectedValues} // Function will trigger on select event
                                    onRemove={resetSelectedValues} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            }
                            
                        </div>
                        {!showInputs && <div className="label-info-row">
                            <span className="run-history-label">Start Time - End Time</span>
                            <span className="run-history-value">{runInfo.fullIntervalFormatted}</span>
                        </div>}
                        {!showInputs && <div className="label-info-row">
                            <span className="run-history-label">Process Start Time</span>
                            <span className="run-history-value">{runInfo.startTimeFormatted}</span>
                        </div>}

                        {showInputs && 
                            <>
                            <div className="label-info-row">
                                <span className="run-history-label">Interval Start Time</span>
                                <div style={{display: 'flex', flexDirection: 'row', gridGap: '10px'}}>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Month</span>
                                        <MonthDropdown isStartInterval={true}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Day</span>
                                        <DayDropdown selectedMonth={startMonth} isStartInterval={true}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Year</span>
                                        <input type="number" className="num-input" defaultValue={startYear} onChange={handleYearChange}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Hour</span>
                                        <HourDropdown isStartInterval={true}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Minute</span>
                                        <MinuteDropdown isStartInterval={true}/>
                                    </div>
                                </div>
                            </div>

                            <div className="label-info-row" style={{alignItems: 'center'}}>
                                <span className="run-history-label">Interval End Time</span>
                                <div style={{display: 'flex', flexDirection: 'row', gridGap: '10px'}}>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Month</span>
                                        <MonthDropdown/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Day</span>
                                        <DayDropdown selectedMonth={endMonth}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Year</span>
                                        <input type="number" className="num-input" defaultValue={endYear} onChange={handleYearChange}/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Hour</span>
                                        <HourDropdown/>
                                    </div>
                                    <div className="label-info-row">
                                        <span className="run-history-label">Minute</span>
                                        <MinuteDropdown/>
                                    </div>
                                </div>                                
                            </div>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button className="button btnCancel" onClick={(handleCancelAction)}>Close</button>
                        {showInputs && 
                            <button className="button btn1" onClick={handleQueueRun}>
                                Run
                            </button>
                        }
                        {!showInputs && runInfo.completed && !isApiRunning &&
                            <button className="button btn1" onClick={handleRun}>
                                Rerun
                            </button>
                        }
                    </div>
                </div>

                <InvalidEntries isVisible={showInvalidEntries} invalidEntries={invalidEntries} handleCancelAction={() => setShowInvalidEntries(false)}/>
            </div>
    );
} 