import ReactPaginate from 'react-paginate';
import { EditableCells } from '../partials/EditableCells';
import '../styles/DimTables.css';
import { MapRow } from './MapRow';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight, FaCompressArrowsAlt, FaExpand, FaExpandArrowsAlt, FaSearch } from 'react-icons/fa';
import { updateSingleCellDimTable } from '../services/dimension-service';
import { useAccount, useMsal } from '@azure/msal-react';
import toast, { Toaster } from 'react-hot-toast';
import '../styles/loading-states.css';
import { useGetToken } from '../hooks/custom-hooks';

export const MapTable = (props) => {
    //{ tableHeaders, yearToAddData, tableData, cellWidth = '130px', dimTable, isCloningProcess, itemsPerPage, canEditDimension, ref }

    /**************************** table states *******************************/
    const [itemOffset, setItemOffset] = useState(0);
    const [fullDataCopy, setFullDataCopy] = useState(props.tableData);
    const [searchResults, setSearchResults] = useState(fullDataCopy);
    const [searchValue, setSearchValue] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [searchExpandWidth, setSearchExpandWidth] = useState('100%');
    const [stylesToShow, setStylesToShow] = useState({});

    const endOffset = itemOffset + props.itemsPerPage;
    const yearVariableName = props.tableHeaders.find(header => header.toLowerCase().includes("year"));

    //const boolean set to true if any row of data in fullDataCopy is checked
    const isAnyChecked = fullDataCopy.some((item) => item.isChecked === true);

    const itemsToView = searchResults.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(searchResults.length / props.itemsPerPage);

    const fullScreenStyle = {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        background: 'black'
    };

    const token = useGetToken();

    useEffect(() => {
        let dataCopy = [...fullDataCopy];
        let newFields = dataCopy.map((item) => ({
            ...item,
            isChecked: true,
            keyValue: getKeyInfo(item, true),
            keyColumnName: getKeyInfo(item, false),
        }));

        setFullDataCopy(newFields);
        setSearchResults(newFields);

        if(props.isCloningProcess) {
            let updatedYears = newFields.map((item) => ({
                ...item,
                [yearVariableName]: props.yearToAddData
            }));

            setFullDataCopy(updatedYears);
            setSearchResults(updatedYears);
            syncTable(updatedYears);
        }
        
    }, []);
    
    /********************* table state handlers **************************/ 

    useEffect(() => {
        if(isFullScreen) {
            setSearchExpandWidth('85%');
        } else {
            setSearchExpandWidth('100%');
        }
    }, [isFullScreen]);    

    const handleNoFullScreen = () => {
        setIsFullScreen(false);
        setStylesToShow({});
    }

    const handleFullScreen = () => {
        setIsFullScreen(true);
        setStylesToShow(fullScreenStyle);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * props.itemsPerPage) % searchResults.length;
        setItemOffset(newOffset);
    };

    const syncTable = (newData) => {
         if(props.syncTableData) { props.syncTableData(newData); }
    }

    const handleSearch = (event) => {
        let input = event.target.value;
        setSearchValue(input);

        let dataCopy = [...fullDataCopy];

        const filteredResults = dataCopy.filter(item => {
        // Check if any property in the item contains the search value
        return Object.values(item).some(value =>
                String(value).toLowerCase().includes(input.toLowerCase())
            );
        });

        setSearchResults(filteredResults);
    }

    // const unCheckAll = () => {
    //     let fullCopy = [...fullDataCopy];
    //     let newFields = fullCopy.map((item) => ({
    //         ...item,
    //         isChecked: false,
    //     }));

    //     setFullDataCopy(newFields);
    // }

    const unCheckAll = () => {
        let fullCopy = [...fullDataCopy];
        let uncheckedFull = fullCopy.map((item) => ({
            ...item,
            isChecked: false,
        }));

        let searchResultsCopy = [...searchResults];
        let newFields = searchResultsCopy.map((item) => ({
            ...item,
            isChecked: false,
        }));

        setFullDataCopy(uncheckedFull);
        setSearchResults(newFields);
    }

    /********************* row state handlers **************************/ 

    const getKeyInfo = (rowData, returnValue) => {
        let val = null;
        Object.entries(rowData).map(([key, value]) => {
            if(key.toLowerCase().includes('key')) {
                val = returnValue ? value : key;
            }            
        });

        return val;
    }

    const handleCheckboxChange = (keyVal, currentStatus) => {

        let fullCopy = [...fullDataCopy];
        const newArray = fullCopy.map((item) =>
            item.keyValue === keyVal ? { ...item, isChecked: !currentStatus } : item
        );

        setFullDataCopy(newArray);
        syncTable(newArray);

        const filteredResults = newArray.filter(item => {
        // Check if any property in the item contains the search value
        return Object.values(item).some(value =>
                String(value).toLowerCase().includes(searchValue.toLowerCase())
            );
        });

        setSearchResults(filteredResults);
    };

    /********************* cell state handlers **************************/ 

    const handleInputFocus = async (newVal, columnName, keyVal, keyColumnName, updateDB = false) => {

        let dataCopy = [...fullDataCopy];
        const updatedData = dataCopy.map((item) => {
            if (item[keyColumnName] === keyVal) {
                return {
                    ...item,
                    [columnName]: newVal
                }
            }
            return item;
        });

        setFullDataCopy(updatedData);
        syncTable(updatedData);
        
        const filteredResults = updatedData.filter(item => {
        // Check if any property in the item contains the search value
        return Object.values(item).some(value =>
                String(value).toLowerCase().includes(searchValue.toLowerCase())
            );
        });

        setSearchResults(filteredResults);
        
        if(updateDB) {
            await updateCell(columnName, newVal, keyVal, keyColumnName);
        }
        
    };

    const handleCloneInputFocus = (keyColumnName, keyVal) => {
        

    }


    const updateCell = async (columnName, newVal, keyVal, keyRowColumnName) => {
        let response = await updateSingleCellDimTable(props.dimTable, columnName, newVal, keyVal, keyRowColumnName, token);
        console.log(response);

        if(response.data) {
            toast.success('Cell Updated.');
        } else {
            toast.error("The update did not save. Please refresh the page and try again.");
        }
    }

    /*******************************************************************/

    function Items({ currentItems }) {

        return (
            <>
            <div className="scrollable-y">
                {currentItems.map((item, index) => (
                    <MapRow
                        token={token}
                        rowCells={item} 
                        index={index} 
                        cellWidth={props.cellWidth} 
                        dimTable={props.dimTable} 
                        isCloningProcess={props.isCloningProcess}
                        canEditDimension={props.canEditDimension}
                        handleCheckboxChange={() => handleCheckboxChange(item.keyValue, item.isChecked)}
                        handleInputDropdownFocus={handleCloneInputFocus}
                        handleCellInputChange={handleInputFocus}
                    />
                ))}
            </div>
            </>
        );
    }

    return(
        <div className='table-container' style={stylesToShow}>
            <div className='search-expand-row' style={{width: searchExpandWidth}}>
                <div className='table-top-left'>
                    <div className='icon-text-row'>
                        <FaSearch size={25}/>
                        <input className='search-bar' onKeyUp={handleSearch} defaultValue={searchValue} placeholder='Search dimension data'/>
                    </div>
                    {isAnyChecked && props.isCloningProcess && <div className='uncheck-btn' onClick={unCheckAll}>
                        <span>Uncheck All</span>
                    </div>}
                </div>
                    {!isFullScreen &&
                    <div className='icon-text-row' style={{cursor: 'pointer'}} onClick={handleFullScreen}>
                        <span>Expand Table</span>
                        <FaExpandArrowsAlt size={20}/>    
                    </div>}

                    {isFullScreen &&
                    <div className='icon-text-row' style={{cursor: 'pointer'}} onClick={handleNoFullScreen}>
                        <span>Exit Full Screen</span>
                        <FaCompressArrowsAlt size={20}/>  
                    </div>}
                </div>
            <div className="scrollable-x">
                <div className="header-dimTable-row wide-table">
                    {props.isCloningProcess && <div style={{width: '35px'}}></div> }
                    {props.tableHeaders.map((item, index) => (
                        <span 
                            key={index}
                            className="header-dimTable"
                            style={{width: props.cellWidth}}>{item.charAt(0).toUpperCase()+item.substring(1,item.length)}</span>
                    ))}            
                </div>
                <Items currentItems={itemsToView}/>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <ReactPaginate
                    activeClassName={'item active '}
                    breakClassName={'item break-me '}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    disabledClassName={'disabled-page'}
                    marginPagesDisplayed={4}
                    nextClassName={"item next "}
                    nextLabel={<FaArrowCircleRight size={15} />}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName={'item pagination-page '}
                    previousClassName={"item previous"}
                    previousLabel={<FaArrowCircleLeft size={15} />}
                />            
            </div>
        </div>
    )
}