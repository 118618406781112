import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import '../App.css';
import '../styles/add-rows.css';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useEffect, useState } from 'react';
import { getDimTableAddRequirements } from '../services/dimension-service';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import 'react-csv-importer/dist/index.css';
import { UploadCSV } from '../partials/UploadCSV';
import { CloneYear } from '../partials/CloneYear';
import { AddRows } from '../partials/AddRows';
import { LoadingTable } from '../shared/LoadingTable';
import { useGetData, useGetToken } from '../hooks/custom-hooks';

export const AddRowsUpload = () => {
    const navigate = useNavigate();
    let { dimTable } = useParams();

    const [searchParams] = useSearchParams();
    const year = searchParams.get('year');
    const conflicts = searchParams.get('conflicts');

    const [loading, setLoading] = useState(true);
    const tableParams = useGetData(getDimTableAddRequirements, null, { dimTable });

    useEffect(() => {
        if(tableParams !== null) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    },[tableParams]);

    const handleHomeBtn = () => {
        navigate(`/`);
    }

    const handleDimTableLanding = () => {
        navigate(`/${dimTable}?year=${year}&conflicts=${conflicts}`)
    }
        
    if(loading) {
        return (
            <div className="App Full-Page">
                <div className="header-content">
                    <span className='App-text action-row'>
                        <span className='back-btn' onClick={handleDimTableLanding}><FaArrowLeft/> {dimTable}</span>
                        <span className='back-btn' onClick={handleHomeBtn}><FaHome/> Home</span>
                    </span>
                    <div className="title-search-container" style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='loading-dimTable-title'></div>
                    </div>
                </div>
                <div className="content-container" >
                    <LoadingTable/>
                </div>
            </div>
        );
    }
    
    return(
        <div className='App Full-Page' style={{gridGap: '0px'}}>
            <span className='App-text action-row'>
                <span className='back-btn' onClick={handleDimTableLanding}><FaArrowLeft/> {dimTable}</span>
                <span className='back-btn' onClick={handleHomeBtn}><FaHome/> Home</span>
            </span>
            <div className='title-row'>
                <span className='title'>{dimTable}</span>
                {/* <FaCircle color='white' size={15}/> */}
                <span className='title'>| Add Rows</span>
            </div>
            <Tabs>
                <TabList>
                    {tableParams.canAddSingleRow && <Tab>Add Manually</Tab>}
                    {tableParams.canUploadCSV && <Tab>CSV Upload</Tab>}
                    {tableParams.canCloneYear && <Tab>Clone Year</Tab>}
                </TabList>
                
                {tableParams.canAddSingleRow && 
                    <TabPanel>
                        <AddRows dimTable={dimTable}/>  
                    </TabPanel>
                }
                {tableParams.canUploadCSV &&
                    <TabPanel>
                        <UploadCSV dimTable={dimTable}/>
                    </TabPanel> 
                }
                {tableParams.canCloneYear &&
                    <TabPanel>    
                        <CloneYear dimTable={dimTable} selectedYear={year}/>
                    </TabPanel> 
                }
            </Tabs>

            {!tableParams.canAddSingleRow && !tableParams.canCloneYear && !tableParams.canUploadCSV &&
                <span>No can do sire.</span>
            }
        </div>
    );
}