import { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { DefaultAzureCredential } from "@azure/identity";
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";
 
export const useGetToken = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [token, setToken] = useState(null);
 
    useEffect(() => {
        if (instance && account) {
            instance.acquireTokenSilent({
                scopes: ['api://ce96083f-3109-49a1-9aec-4b91f3a2a72d/Invoke'],
                account: account
            })
            .then((response) => {
                if(response) {
                    setToken(response.accessToken);
                }
            });
        }
    }, [account, instance]);
 
    return token;
}

export const useGetData = (serviceFunction, payload = null, queryParams = null, dependencies = []) => {
    const [data, setData] = useState(null);
    const token = useGetToken();

    useEffect(() => {
        const getData = async () => {
            let queryConfig = {
                accessToken: token,
                payload: payload,
                params: queryParams
            };
            if (payload) queryConfig.payload = payload;
            const { data, error } = await serviceFunction(queryConfig);
            if (error) {
                console.error(error);
            } else {
                setData(data);
            }
        }
        token && getData();
    }, [...dependencies, serviceFunction, token, payload]);

    return data;
}